import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import pricing from '../../../services/pricing';
import { toTwoDigitsOrMore } from '../../../services/formatting';
import { mobileThresholdPixels, margins, colors } from './StyledComponents';

import pant from '../../../assets/pricing/big/pant.jpg';
import haut from '../../../assets/pricing/big/shirt.jpg';
import robe from '../../../assets/pricing/big/dress.jpg';
import pull from '../../../assets/pricing/big/pullover.jpg';
import mant from '../../../assets/pricing/big/coat.jpg';
import jupe from '../../../assets/pricing/big/skirt.jpg';
import ling from '../../../assets/pricing/big/shorts.jpg';
import deco from '../../../assets/pricing/big/curtain.jpg';

const clothesImages = { pant, haut, robe, pull, mant, jupe, ling, deco };

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  ${props => props.image && `background: url(${props.image})`};
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
    height: 450px;
    margin-left: -${margins.s};
  }
`;

const PriceContainer = styled.div`
  background-color: ${colors.white};
  width: 55%;
  min-height: 45%;
  padding: ${margins.l};
  opacity: 0.95;

  @media (max-width: ${mobileThresholdPixels}) {
    min-width: 280px;
    padding: ${margins.s};
  }
`;

const Table = styled.table`border-collapse: collapse;`;

const Tr = styled.tr``;

const Th = styled.th`
  font-family: Roboto;
  font-weight: bold;
  padding: 14px 0px;
  text-transform: capitalize;
  border-bottom: 3px solid ${colors.navy};
  text-align: center;
  width: 1%;
  ${props => props.clothName && `
    text-align: left;
    text-transform: uppercase;
    width: 30%;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 11px;
    border-bottom: 2px solid ${colors.navy};
    ${props => props.clothName && 'width: 15%;'}
  }
`;

const Td = styled.td`
  font-family: Roboto;
  padding: 20px 0px;
  border-top: 1px solid ${colors.navy};
  text-align: center;
  ${props => props.alterationName && 'text-align: left;'};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 11px;
    padding: 15px 0px;
  }
`;

function alterationsToShow(clothRef) {
  const alterations = clothRef.categories[0].items;
  if (clothRef.types.length > 0) return alterations.filter(alteration => alteration.type === 'SIMPLE');
  return alterations;
}

function complexAlterationsToShow(clothRef) {
  const alterations = clothRef.categories[0].items;
  return alterations.filter(alteration => alteration.type === 'COMPLEX');
}

function getComplexPrice(clothRef, alteration) {
  const complexAlteration = complexAlterationsToShow(clothRef)
    .find(alterationIt => (alterationIt.name === alteration.name && alterationIt.type === 'COMPLEX'));
  if (complexAlteration) return complexAlteration.price;
  return alteration.price;
}

const PriceRow = (texts) => {
  if (texts[2]) {
    return (
      <Tr>
        <Td alterationName>{texts[0]}</Td>
        <Td>{texts[1]}</Td>
        <Td>{texts[2]}</Td>
      </Tr>
    );
  }
  return (
    <Tr>
      <Td alterationName>{texts[0]}</Td>
      <Td>{texts[1]}</Td>
    </Tr>
  );
};

const AlterationsPrice = ({ selectedClothSlug }) => {
  const clothRef = pricing.find(clothIt => clothIt.slug === selectedClothSlug);
  const hasMultipleType = clothRef.types.length > 0;
  return (
    <Container image={clothesImages[selectedClothSlug]}>
      <PriceContainer>
        {hasMultipleType ?
          <Table>
            <thead>
              <Tr>
                <Th clothName>{clothRef.name}</Th>
                <Th>{clothRef.types[0].name}</Th>
                <Th>{clothRef.types[1].name}</Th>
              </Tr>
            </thead>
            <tbody>
              {alterationsToShow(clothRef).map(alteration =>
                PriceRow([
                  alteration.name,
                  `${toTwoDigitsOrMore(alteration.price)}€`,
                  `${toTwoDigitsOrMore(getComplexPrice(clothRef, alteration))}€`,
                ]),
              )}
            </tbody>
          </Table> :
          <Table>
            <thead>
              <Tr>
                <Th clothName>{clothRef.name}</Th>
                <Th>Simple</Th>
              </Tr>
            </thead>
            <tbody>
              {alterationsToShow(clothRef).map(alteration =>
                PriceRow([alteration.name, `${toTwoDigitsOrMore(alteration.price)}€`]),
              )}
            </tbody>
          </Table>
        }
      </PriceContainer>
    </Container>
  );
};

AlterationsPrice.propTypes = {
  selectedClothSlug: PropTypes.string.isRequired,
};

export default AlterationsPrice;
