import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../../withAppContext';
import pricings from '../../../services/pricing';
import Slider from './Slider';
import { mobileThresholdPixels, fontSizes, margins, colors } from './StyledComponents';

import arrowLeft from '../../../tilli-components/src/assets/arrow.left.navy.svg';
import arrowRight from '../../../tilli-components/src/assets/arrow.right.navy.svg';
import pant from '../../../assets/pricing/small/pants.jpg';
import haut from '../../../assets/pricing/small/shirt.jpg';
import robe from '../../../assets/pricing/small/dress.jpg';
import pull from '../../../assets/pricing/small/pullover.jpg';
import mant from '../../../assets/pricing/small/coat.jpg';
import jupe from '../../../assets/pricing/small/skirt.jpg';
import ling from '../../../assets/pricing/small/shorts.jpg';
import deco from '../../../assets/pricing/small/curtain.jpg';

const clothesImages = { pant, haut, robe, pull, mant, jupe, ling, deco };

/*
  for 'abcd & efgh' return
  abcd
   &
  efgh
  for 'abcd' return 'abcd'
*/
function splitName(name) {
  const splitedName = name.replace(/ /g, '').split('&');
  if (splitedName.length > 1) return <span>{splitedName[0]} <br /> & <br /> {splitedName[1]}</span>;
  return name;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${margins.m} ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: row;
    justify-content: space-around;
    margin: 0px 0px ${margins.l} 0px;
  }
`;

const ControlContainer = styled.div``;

const ControlImg = styled.img`
  width: 18px;
  transform: rotate(90deg);

  @media (max-width: ${mobileThresholdPixels}) {
    transform: none;
  }
`;

const Square = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${props => props.isSelected ? `${margins.s} 0px` : `calc(${margins.s} + 1px) 1px`};
  border: 1px solid ${colors.navy};
  ${props => props.isSelected && 'border-width: 2px'};
  width: 150px;
  height: 150px;
  cursor: pointer;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 80px;
    height: 80px;
    margin: ${props => props.isSelected ? `${margins.s} 10px` : `calc(${margins.s} + 1px) 11px`};
  }
`;

const Span1 = styled.span`
  position: absolute;
  font-family: Roboto;
  color: ${colors.white};
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

class ClothesSlider extends React.Component {
  renderClothSelector(cloth) {
    const { isMobile } = this.props.context;
    return (
      <Square
        key={cloth.slug}
        isSelected={cloth.slug === this.props.selectedClothSlug}
        backgroundImage={clothesImages[cloth.slug]}
        onClick={() => this.props.changeSelectedCloth(cloth.slug)}
      >
        {isMobile ?
          <Span1>{splitName(cloth.name)}</Span1> :
          <Span1>{cloth.name}</Span1>
        }
      </Square>
    );
  }

  render() {
    const { isMobile } = this.props.context;
    return (
      <Container>
        <ControlContainer>
          <ControlImg alt="Vêtement précédent" src={arrowLeft} onClick={() => this.track.prev()} />
        </ControlContainer>
        <Slider
          preserveState
          isMobile={isMobile}
          column={!isMobile}
          viewsToShow={!isMobile ? 3 : 2}
          ref={(c) => { this.track = c; }}
        >
          {pricings.map(cloth => this.renderClothSelector(cloth))}
        </Slider>
        <ControlContainer>
          <ControlImg alt="Vêtement suivant" src={arrowRight} onClick={() => this.track.next()} />
        </ControlContainer>
      </Container>
    );
  }
}

ClothesSlider.propTypes = {
  changeSelectedCloth: PropTypes.func.isRequired,
  selectedClothSlug: PropTypes.string.isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(ClothesSlider);
