import React from 'react';

import MenuBar from '../../components/Home/MenuBar';
import Footer from '../../components/Home/Footer';
import Pricings from '../../components/Home/v2/Pricings';
import PricingsFAQ from '../../components/Home/v2/PricingsFAQ';
import PricingsModal from '../../components/Home/v2/PricingsModal';
import { Container, Content } from '../../components/Home/v2/StyledComponents';
import Layout from '../../layouts/index';
import pricings from '../../services/pricing';

class PricingsPage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Layout routeSlug="PricingsPage">
        {this.state.showAllPricings ?
          <PricingsModal
            pricings={pricings}
            close={() => this.setState({ showAllPricings: false })}
          /> :
          <Container>
            <MenuBar />
            <Content><Pricings
              openAllPricings={() => this.setState({ showAllPricings: true })}
            /></Content>
            <Content><PricingsFAQ /></Content>
            <Footer />
          </Container>
        }
      </Layout>
    );
  }
}

export default PricingsPage;
